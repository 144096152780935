.axis text {
  fill: rgb(73, 73, 73);
}

.axis path {
  stroke: rgb(39, 39, 39);
}

.axis .baseline {
  stroke: rgb(22, 22, 22);
}

.customGrid .tick {
  stroke: rgb(190, 190, 190);
  opacity: 0.2;
  stroke-dasharray: 5, 5;
}
.customGrid path {
  stroke: rgb(190, 190, 190);
  stroke-width: 0.2;
  stroke-dasharray: 5, 5;
}

.tooltip-content {
  border: 1px solid black;
  background: white;
  padding: 20px;
  position: relative;
  transform: translate(calc(-50% + 7px), calc(-100% - 20px));
}

.tooltip-content:before {
  background: inherit;
  content: '';
  padding: 0px;
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 99;
  border-right: inherit;
  border-bottom: inherit;
  bottom: -8px;
  left: calc(50% - 15px);
}

.scatter-container {
  margin: auto;
}

.svg-plot {
  position: absolute;
}

.canvas-plot {
  position: absolute;
}
.legendstext {
  fill: rgb(88, 88, 88);
  font-size: '40px';
}
