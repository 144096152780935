.pulse {
  animation: pulse 1s infinite alternate-reverse;
  image-rendering:crisp-edges;
  text-rendering: optimizeLegibility;
  shape-rendering: geometricPrecision;
}

@keyframes pulse {
  0% {
    transform: scale(0.90);
  }
  70% {
    transform: scale(1.1);
    /* box-shadow: 0 0 0 50px rgba(#222, 0); */
  }
  100% {
    transform: scale(0.9);
    /* box-shadow: 0 0 0 0 rgba(#5a99d4, 0); */
  }
}
