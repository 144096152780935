#AppMain {
  margin-top: 0px;
  width: auto;
  background: url('../images/circle-bg.svg') 80vw 70vh no-repeat #1d3556;
  background-size: 1000px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Sidebar */
.ui.menu .item {
  text-align-last: left;
  padding: 10px !important;
}

/* Version shown in sidebar menu after login */
.sidebar .version {
  left: 0;
  bottom: 60px;
}
