:root {
  --adjustment-baseline: 10px;
  /* All level tier colors. Names are as defined in the design manual */
  /* Primary Colors. */
  --waterlinked-ocean-blue: #1d3556;
  --waterlinked-signal-green-light: #4bef8f;
  --waterlinked-signal-green-dark: #2b9c6a;
  --waterlinked-signal-green-white: #f1f9ee;

  /* Secondary Colors */
  --waterlinked-red: #d4484b;
  --waterlinked-signal-yellow: #ffc83b;

  /* Tertiary Colors */
  --waterlinked-dark-blue: #2c485d;
  --waterlinked-light-gray: #e6e9ec;

  /* Colors from waterlinked.com */
  --website-deep-blue: #192e4b;
}
