.sensor-sidebar {
  z-index: 10;
}

.sensor-sidebar-expanded {
  right: 25%;
}

.ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--waterlinked-signal-green-light) !important;
}

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
  color: var(--waterlinked-signal-green-light) !important;
}

/* Adjust the widths of toggles. This only applies to the analytics page */
.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  left: 1.15rem;
}

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
  padding-left: 3.2rem;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  width: 2.5rem;
}

/* Date range overrides */
.DateInput {
  width: auto;
  max-width: 100px;
}

.DateInput_fang {
  left: 50% !important;
}

.DateInput_input__focused {
  border: none !important;
  font-weight: 700 !important;
  border-radius: 4px !important;
  background-color: var(--waterlinked-signal-green-light) !important;
}

.DateRangePickerInput {
  display: flex !important; 
  justify-content: space-between;
  align-items: center !important;
  border-radius: 4px !important;
}

.DateInput_input {
  font-size: 1rem !important;
  padding: 8px 4px !important;
  line-height: 1.2rem !important;
  text-align: center;
  color: #222 !important;
  /* border: none; */
  /* text-align: left !important; */
}