.w-10 {
  width: 10%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.mw-300px {
  max-width: 300px;
}

.mw-400px {
  max-width: 400px;
}

.mw-500px {
  max-width: 500px;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

/* Margins */
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: var(--adjustment-baseline) !important;
}

.m-2 {
  margin: calc(var(--adjustment-baseline) * 1.5) !important;
}

.m-3 {
  margin: calc(var(--adjustment-baseline) * 2) !important;
}

.mt-1 {
  margin-top: var(--adjustment-baseline) !important;
}

.mt-2 {
  margin-top: calc(var(--adjustment-baseline) * 1.5) !important;
}

.mt-3 {
  margin-top: calc(var(--adjustment-baseline) * 2) !important;
}

.me-1 {
  margin-right: var(--adjustment-baseline) !important;
}

.me-2 {
  margin-right: calc(var(--adjustment-baseline) * 1.5) !important;
}

.me-3 {
  margin-right: calc(var(--adjustment-baseline) * 2) !important;
}

.ms-1 {
  margin-left: var(--adjustment-baseline) !important;
}

.ms-2 {
  margin-left: calc(var(--adjustment-baseline) * 1.5) !important;
}

.ms-3 {
  margin-left: calc(var(--adjustment-baseline) * 2) !important;
}

.mb-1 {
  margin-bottom: var(--adjustment-baseline) !important;
}

.mb-2 {
  margin-bottom: calc(var(--adjustment-baseline) * 1.5) !important;
}

.mb-3 {
  margin-bottom: calc(var(--adjustment-baseline) * 2) !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-1 {
  margin-left: calc(var(--adjustment-baseline)) !important;
  margin-right: calc(var(--adjustment-baseline)) !important;
}

.mx-2 {
  margin-left: calc(var(--adjustment-baseline) * 1.5) !important;
  margin-right: calc(var(--adjustment-baseline) * 1.5) !important;
}

.mx-3 {
  padding-left: calc(var(--adjustment-baseline) * 2) !important;
  margin-right: calc(var(--adjustment-baseline) * 2) !important;
}

.my-1 {
  margin-top: calc(var(--adjustment-baseline)) !important;
  margin-bottom: calc(var(--adjustment-baseline)) !important;
}

.my-2 {
  margin-top: calc(var(--adjustment-baseline) * 1.5) !important;
  margin-bottom: calc(var(--adjustment-baseline) * 1.5) !important;
}

.my-3 {
  margin-top: calc(var(--adjustment-baseline) * 2) !important;
  margin-bottom: calc(var(--adjustment-baseline) * 2) !important;
}

.ms-auto {
  margin-left: auto !important;
}

.me-auto {
  margin-right: auto !important;
}

/* Paddings */

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: var(--adjustment-baseline) !important;
}

.p-2 {
  padding: calc(var(--adjustment-baseline) * 1.5) !important;
}

.p-3 {
  padding: calc(var(--adjustment-baseline) * 2) !important;
}

.pt-1 {
  padding-top: var(--adjustment-baseline) !important;
}

.pt-2 {
  padding-top: calc(var(--adjustment-baseline) * 1.5) !important;
}

.pt-3 {
  padding-top: calc(var(--adjustment-baseline) * 2) !important;
}

.pe-1 {
  padding-right: var(--adjustment-baseline) !important;
}

.pe-2 {
  padding-right: calc(var(--adjustment-baseline) * 1.5) !important;
}

.pe-3 {
  padding-right: calc(var(--adjustment-baseline) * 2) !important;
}

.ps-1 {
  padding-left: var(--adjustment-baseline) !important;
}

.ps-2 {
  padding-left: calc(var(--adjustment-baseline) * 1.5) !important;
}

.ps-3 {
  padding-left: calc(var(--adjustment-baseline) * 2) !important;
}

.pb-1 {
  padding-bottom: var(--adjustment-baseline) !important;
}

.pb-2 {
  padding-bottom: calc(var(--adjustment-baseline) * 1.5) !important;
}

.pb-3 {
  padding-bottom: calc(var(--adjustment-baseline) * 2) !important;
}

.px-1 {
  padding-left: calc(var(--adjustment-baseline)) !important;
  padding-right: calc(var(--adjustment-baseline)) !important;
}

.px-2 {
  padding-left: calc(var(--adjustment-baseline) * 1.5) !important;
  padding-right: calc(var(--adjustment-baseline) * 1.5) !important;
}

.px-3 {
  padding-left: calc(var(--adjustment-baseline) * 2) !important;
  padding-right: calc(var(--adjustment-baseline) * 2) !important;
}

.py-1 {
  padding-top: calc(var(--adjustment-baseline)) !important;
  padding-bottom: calc(var(--adjustment-baseline)) !important;
}

.py-2 {
  padding-top: calc(var(--adjustment-baseline) * 1.5) !important;
  padding-bottom: calc(var(--adjustment-baseline) * 1.5) !important;
}

.py-3 {
  padding-top: calc(var(--adjustment-baseline) * 2) !important;
  padding-bottom: calc(var(--adjustment-baseline) * 2) !important;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.bottom-right {
  right: 0;
  bottom: 0;
}

.top-right {
  right: 0;
  top: 0;
}

.shadow {
  box-shadow: 0 0 8px rgb(190, 190, 190);
}

.bg-white {
  background-color: #fff;
}

.bg-light-gray {
  background-color: var(--waterlinked-light-gray) !important;
}

.bg-blue {
  background-color: var(--waterlinked-ocean-blue) !important;
}

.bg-red {
  background-color: var(--waterlinked-red) !important;
}

.bg-light-green {
  background-color: var(--waterlinked-signal-green-light) !important;
}

.bg-dark-green {
  background-color: var(--waterlinked-signal-green-dark) !important;
}

.bg-dark-blue {
  background-color: var(--waterlinked-dark-blue) !important;
}

.bg-deep-blue {
  background-color: var(--website-deep-blue) !important;
}

.bg-white-opaque {
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-blue-opaque {
  background-color: rgba(29, 53, 86, 30%)
}

.bg-none {
  background: none !important;
}

.border-none {
  border: none !important;
}

.hover-intent:hover {
  opacity: 0.9;
  cursor: pointer;
}

.hover-intent-background:hover {
  opacity: 0.9;
  cursor: pointer;
  background-color: var(--website-deep-blue);
  color: #fff;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.hidden {
  display: none !important;
}

.rounded {
  border-radius: 5px;
}

.d-flex {
  display: flex !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

/* Add support for the hover pseudo for all buttons using override classes */
.ui.button[class*='bg-']:hover {
  transition: all 0.25s ease-in;
  opacity: 0.9 !important;
}
