@font-face {
  font-family: 'Averta Regular';
  src: local('Averta Regular'), local('Averta Regular'), url('../Fonts/Averta.woff2') format('woff2'),
    url('../Fonts/Averta.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Regular'), local('Open Sans Regular'), url('../Fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../Fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Medium';
  src: local('Open Sans Medium'), local('Open Sans Medium'), url('../Fonts/OpenSans-Medium.woff2') format('woff2'),
    url('../Fonts/OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', 'Segoe UI', 'Ubuntu', 'Fira Sans', 'Droid Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Averta Regular', Helvetica, sans-serif !important;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-white {
  color: #fff !important;
}

.text-dark {
  color: #222 !important;
}

.text-blue {
  color: var(--waterlinked-ocean-blue);
}

.text-dark-green {
  color: var(--waterlinked-signal-green-dark);
}

.text-light-green {
  color: var(--waterlinked-signal-green-light);
}

.text-red {
  color: var(--waterlinked-red);
}

.text-yellow {
  color: var(--waterlinked-signal-yellow);
}

.text-xs {
  font-size: 80% !important;
}

.text-sm {
  font-size: 90% !important;
}

.text-lg {
  font-size: 150%;
}

.text-xl {
  font-size: 180%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-capitalize-fl:first-letter {
  text-transform: capitalize;
}

.text-bold {
  font-weight: 700;
}

.text-italic {
  font-style: italic;
}
