/* CSS Overrides for the Welcome screen / route */
.welcome {
  max-width: 1200px;
  border-radius: 5px;
  font-size: 120%;
}

.welcome .text {
  min-height: 300px;
}

.welcome .splash {
  /* background: url('../images/welcome-bg5.jpg') top center no-repeat; */
  background-size: cover;
  /* height: 75vh; */
}

.welcome .splash .hero {
  margin-top: 140px;
  background-color: rgba(255, 255, 255, 0.05);
  max-width: 700px;
}

/* Overrides */
.welcome .steps .step > .icon {
  font-size: 1.4rem;
}

.ui.steps .step {
  padding: 1.2em 1em !important;
}

.ui.vertical.steps {
  height: 50vh;
  min-height: 350px;
}

.page-content {
  /* height: 75vh; */
  overflow-x: hidden;
  overflow-y: scroll;
}

.ui.steps .step.active {
  background-color: #2b9c6a !important;
}

.ui.steps .step.active .title {
  color: #fff !important;
}

.ui.steps .step.active:after {
  background-color: #fff;
}

.welcome ::-webkit-scrollbar {
  display: none;
}

.steps i {
  border: 1px solid transparent;
}

.steps i.current-step {
  color: #fff !important;
  border-radius: 50%;
}
